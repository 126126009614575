import React from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby';
import { Wrap as ServicesWrap, List as ServicesList } from '../navigation/SecondaryNav';

const ServicesNav = ({ current }) => {
    const services = useStaticQuery(graphql`
        {
            allContentfulServices(sort: { order:ASC, fields: orderNum }) {
                edges {
                    node {
                        title
                        link
                        orderNum
                        icon {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);
    return (
            <ServicesWrap>
                <div className="container">
                    <div className="menu-title">
                        <h2>step by step guide</h2>
                        <h3>of advertising setup and optimization</h3>
                    </div>
                    <ServicesList>
                        {services.allContentfulServices.edges.map(({ node }) => {
                            return (
                                <li key={node.title} className="service">
                                    <Link to={`/services/${node.link}/`} activeClassName="current">
                                        <img src={node.icon.file.url} alt={node.title} />
                                        <div>Stage {node.orderNum}.{node.title}</div>
                                    </Link>
                                </li>
                            );
                        })}
                    </ServicesList>
                </div>
            </ServicesWrap>
    );
}


export default ServicesNav;

