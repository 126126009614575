import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/styles';

const ToolsWrap = styled.div`
    ul {
        list-style: none;
        margin: 0;
        margin-bottom: 3rem;

        li {
            border: 1px solid ${colors.lightBlue2};
            border-radius: 2px;
            margin-bottom: 1.4rem;
            display: flex;
            padding: 1rem;
            padding-right: 2.5rem;
            padding-top: 1.5rem;

            .img-box {
                width: 20%;
                flex-grow: 0;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 1rem;
                padding-top: 4.5rem;
                padding-bottom: 4.5rem;
            }

            h2 {
                font-weight: 700;
                color: ${colors.orange};
                text-transform: uppercase;
                font-size: 1.3rem;
                letter-spacing: .05em;
                margin-bottom: 0.8rem;
                margin-top: 1rem;
            }
        }
    }
`;


const Tools = ({data}) => {
    return (
        <ToolsWrap>
            <div className="container">
                <h1 className="huge">Tools</h1>
                <ul>
                    {data.map((tool) => {
                        return (
                            <li key={tool.title}>
                                <div className="img-box">
                                    <img src={tool.icon.file.url} alt={tool.title} />
                                </div>
                                <div>
                                    <h2>{tool.title}</h2>
                                    {tool.description.description}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </ToolsWrap>
    );
}

export default Tools;