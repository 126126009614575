import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { colors } from '../../constants/styles';
import { device } from '../../constants/mediaQueries';

const SourcesWrap = styled.div`
    .sources {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 1rem;

        > div {
            flex-grow: 0;
            flex-shrink: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 1rem 2rem;
            position: relative;
            cursor: pointer;

            h3 {
                text-align: center;
                font-weight: 700;
                font-size: 1rem;
                color: ${colors.white};
                text-transform: none;
            }
            
    
            @media ${device.deviceXXS} {
                width: 49%
            }
    
            @media ${device.deviceS} {
                width: 19.5%;         

                &.active:after {
                    content: '';
                    width: 19px;
                    height: 16px;
                    background: url(${props => props.arrowURL});
                    background-size: 100%;
                    position: absolute;
                    bottom: -19px;
                    left: 47%;
                    z-index: 3;
                }
            }
        }
    }
    .description {
        border: 1px solid ${colors.lightBlue2};
        font-weight: 500;
        color: ${colors.grey};
        font-size: 0.95rem;
        line-height: 1.5em;
        position: relative;
        padding: 1.2rem 1.7rem;
    }
`;

const ClientSources = ({ data }) => {
    const [activeSource, setActiveSource] = useState(1);
    const [activeDescription, setActiveDescription] = useState(data[0].description.description);

    // Scroll to description for smaller devices
    const scrollToRef = (ref) => window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'});
    const descriptionRef = useRef(null);
    const scrollToDescription = () => {
        if (window.innerWidth <= 768){
            scrollToRef(descriptionRef);
        }
    }    
    
    const handleSourceCLick = (ind) => {
        setActiveSource(ind);
        setActiveDescription(data[ind].description.description);
        scrollToDescription();
    }

    const arrow = useStaticQuery(graphql`
        {
            allFile(filter: {name: {eq: "up"}, extension: {eq: "png"}}){
                edges {
                    node {
                        publicURL
                    }
                }
            }
        }
    `);
    
    return (
        <div className="container">
            <h1 className="huge">These client sources will work for you with maximum impact</h1>
            <SourcesWrap arrowURL={arrow.allFile.edges[0].node.publicURL}>
                <div className="sources">
                    {data.map((source, ind) => {
                        return (
                            <div 
                                key={source.title} 
                                style={{backgroundImage: `url(${source.background.file.url})`}} 
                                onClick={() => handleSourceCLick(ind)} 
                                className={ind === activeSource ? 'active' : undefined}>
                                <img src={source.icon.file.url} alt={source.title} />
                                <h3>{source.title}</h3>
                            </div>
                        );
                    })}
                </div>
                <div className="description" ref={descriptionRef}>
                    {activeDescription}
                </div>
            </SourcesWrap>
        </div>
    );
}

export default ClientSources;