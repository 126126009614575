import React from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import ServicesNav from '../components/services/ServicesNav';
import ClientSources from '../components/services/ClientSources';
import Tools from '../components/services/Tools';
import Advantages from '../components/reusable/Advantages';
import Phones from '../components/reusable/Phones';

export default ({
    data:  {
        allContentfulServices : {
            edges: [
                {
                    node
                }
            ]
        }
    }
}) => {
    const contentText = (node.content && node.content.json) ? documentToReactComponents(node.content.json) : null;
    return (
        <Layout>
            <Helmet>
                <meta charset="utf-8" />
                <title>{node.title}</title>
            </Helmet>
            <ServicesNav />
            <div className="container">
                <h1>{node.title}</h1>
                {contentText}
                
            </div>
            {node.clientSources && <ClientSources data={node.clientSources} />}
            {node.tools && <Tools data={node.tools} />}
            <Advantages />
            <Phones />
        </Layout>
    );
};

export const query = graphql`
    query($slug: String!) {
        allContentfulServices(filter: {link: {eq: $slug } }){
            edges {
                node {
                    title
                    link
                    content {
                        json
                    }
                    clientSources {
                        title
                        icon {
                            file {
                                url
                            }
                        }
                        background {
                            file {
                                url
                            }
                        }
                        description {
                            description
                        }
                    }
                    tools {
                        description {
                            description
                        }
                        icon {
                            file {
                                url
                            }
                        }
                        title
                    }
                }
            }
        }
    }
`;